import VerifyComponent from 'app/components/Verify/VerifyComponent';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export function Verify() {
  return (
    <>
      <Helmet>
        <title>Grab A Snak-Verify Login </title>
        <meta name="description" content="Grab A Snak- Verify Login" />
      </Helmet>
      <VerifyComponent/>
    </>
  );
}
