import { FakeProducts } from "demo/FakeProduct";
import { Dispatch } from "redux";
import { CART_ADD_ITEM, EMPTY_CART } from "redux/constants/customer/CartCustomer";

export const AddtoCart = (id:string,products:any[],type:string="add") =>  async (dispatch:Dispatch) => {
    const data = products?.filter(p => p._id === id)[0]
    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            data
        },
        typeOfAction:type
    })
}

export const emptyCart = () =>   (dispatch:Dispatch) => {
    
    dispatch({
        type: EMPTY_CART,
    })
}

// let products;
//                 let restproducts =state.filter(p => p.item?.id !== existItem.item?.id)
//                 let existtedItem = state.filter(p => p.item?.id === existItem.item?.id)[0]
//                 existtedItem.qty= existtedItem.qty! + 1
//                 products = [restproducts, existtedItem]
//                 return products;