import { changePassword, getPasswordResetLink } from 'api/apis/user'
import { CaretLeft, Eye, EyeSlash, User } from 'phosphor-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

const ChnagePassword = () => {
  const [showPass1, setShowPass1] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [Pass1, setPass1] = useState(' ')
  const [Pass2, setPass2] = useState(' ')
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const email = queryParams.get('email');
  const navigate = useNavigate()
  useEffect(() => {
    if (!code || !email) {
      toast.error("Broken Link Plz Genrate Again ")
      navigate("/login")
    }
  }, [code, email])


  const handleSubmit = async () => {
    let data = {
      "code": code,
      "email": email,
      "password": Pass1
    }
    try {
      toast.loading("Changing Password")
      if (Pass1 !== "" || Pass2 !== "" || Pass2 === Pass2) {
        const response = await changePassword(data)
        toast.dismiss()
        navigate("/login")
      } else {
        toast.dismiss()

        toast.error("Password Not Match")
      }
    } catch (error: any) {
      toast.dismiss()
      console.log(error?.response)
      toast.error(error?.response?.data?.message)

    }
  }


  return (
    <>
     <Helmet>
        <title>Grab A Snak-Change Password </title>
        <meta name="description" content="Grab A Snak-Change Password Login" />
      </Helmet>
      <div className='flex bg-[#F7F7F7]  h-[100vh] justify-center items-center'>
        <Link to="/login"><CaretLeft className='absolute top-10 left-10 p-2 rounded-full border border-[#CDD1CC] bg-[#CDD1CC text-[#CDD1CC] cursor-pointer' size={36} weight='bold' /></Link>
        <div className='lg:w-[35%] w-[90%] rounded-xl shadow-md p-12 bg-white'>
          <img src="images/GAS.png" alt="" />
          <p className='lg:my-10 my-6  flex gap-2 '><User className='text-[#FA4756]' size={26} weight="bold" /> <span className='text-theme-black lg:text-[30px] text-[20px] font-bold'>Reset Your Password</span></p>

          <form className='mt-6' action="">
            <label className=' text-theme-black pl-1' htmlFor="email">New Password</label>
            <div className='relative  mb-5'>
              <input value={Pass1} onChange={(e) => setPass1(e.target.value)} className='w-full focus:outline-[#103734] my-2 border rounded-full px-4 py-2' type={showPass1 ? 'text' : 'password'} />
              {showPass1 ?
                <Eye onClick={() => setShowPass1(!showPass1)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" /> :
                <EyeSlash onClick={() => setShowPass1(!showPass1)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" />
              }
            </div>

            <label className=' text-theme-black pl-1' htmlFor="email">Confirm New Password</label>
            <div className='relative  mb-5'>
              <input value={Pass2} onChange={(e) => setPass2(e.target.value)} className='w-full focus:outline-[#103734] my-2 border rounded-full px-4 py-2' type={showPass2 ? 'text' : 'password'} />
              {showPass2 ?
                <Eye onClick={() => setShowPass2(!showPass2)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" /> :
                <EyeSlash onClick={() => setShowPass2(!showPass2)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" />
              }
            </div>
            <button type='button' onClick={() => handleSubmit()} className='w-full py-2 rounded-full bg-theme-green text-white mt-4 mb-6'>Reset</button>
          </form>
        </div>
        <img src="images/Group632.svg" className='absolute bottom-0' alt="" />
      </div>
    </>
  )
}

export default ChnagePassword