
import { categoriesForCustomer, productsForCustomer } from "api/apis/Customer";
import { Dispatch } from "redux";
import { CATEGORIES_DATA_CUSTOMER_FAIL, CATEGORIES_DATA_CUSTOMER_REQ, CATEGORIES_DATA_CUSTOMER_SUCC, PRODUCTS_DATA_CUSTOMER_FAIL, PRODUCTS_DATA_CUSTOMER_FILTER_NAME, PRODUCTS_DATA_CUSTOMER_REQ, PRODUCTS_DATA_CUSTOMER_SUCC } from "./type";

export const getProductsByLocationIdClient = (id: string|undefined, callback=()=>{} ) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: PRODUCTS_DATA_CUSTOMER_REQ })
        const res = await productsForCustomer(id)
        console.log(res.data.data)
        dispatch({
            type: PRODUCTS_DATA_CUSTOMER_SUCC,
            payload: res.data.data
        })
    } catch (error: any) {
        console.log(error)
        callback()
        dispatch({
            type: PRODUCTS_DATA_CUSTOMER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }

}
export const getCategoriesByLocationIdClient = (id: string ) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CATEGORIES_DATA_CUSTOMER_REQ })
        const res = await categoriesForCustomer(id)
        console.log(res.data.data)
        dispatch({
            type: CATEGORIES_DATA_CUSTOMER_SUCC,
            payload: res.data.data
        })
    } catch (error: any) {
        console.log(error)
        dispatch({
            type: CATEGORIES_DATA_CUSTOMER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }

}

export const getProductsForAddLocationFilterCustomer = (type, data, fulldata) => async (dispatch: Dispatch) => {
    try {
        if (type === "search") {
          
            const filterdData = fulldata.filter((val: any) => {
                
                
                
               return  val?.product?.name?.toLowerCase().includes(data.toLowerCase())})
            
            dispatch({
                type: PRODUCTS_DATA_CUSTOMER_FILTER_NAME,
                payload:filterdData
            })
        } else if (type === "category") {
            const filterdData = fulldata.filter((val: any) => val?.product?.category?._id===data)
            dispatch({
                type: PRODUCTS_DATA_CUSTOMER_FILTER_NAME,
                payload:filterdData
            })
        } else {
            dispatch({
                type: PRODUCTS_DATA_CUSTOMER_FILTER_NAME,
                payload:fulldata
            })
        }


    } catch (error: any) {
        console.log(error)
        
    }

}