import  axios  from "axios"
import { accessToken } from "utils/token"

export const productsForCustomer = async (location:string|undefined) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/product/list?slug=${location}`, accessToken())


}
export const transactionOfCustomer = async (phone:string | number) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/customer/orders?phone_number=${phone}`, accessToken())


}
export const createOrder = async (data:any) => {

    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/order/create`,data)


}
export const geetSingleOrderDetails = async (id:string|null) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/order?order_id=${id}`)

}



export const categoriesForCustomer = async (location:string) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/product/category?slug=${location}`, accessToken())


}


 