import React from 'react'
import {HashLoader}from "react-spinners"
const Loading = ({text="Loading"}) => {
  return (
    <div className='flex flex-col items-center justify-center space-y-4' >
         <HashLoader  color='#EDB037' loading={true}/>
         <span className={"text-theme-green text-lg"}>
             {text}
         </span>
    </div>
  )
}

export default Loading