
import { categoriesForCustomer, productsForCustomer } from "api/apis/Customer";
import { Dispatch } from "redux";
import { CATEGORIES_DATA_CUSTOMER_FAIL, CATEGORIES_DATA_CUSTOMER_REQ, CATEGORIES_DATA_CUSTOMER_SUCC, CUSTOMER_DATA_SAVE, PRODUCTS_DATA_CUSTOMER_FAIL, PRODUCTS_DATA_CUSTOMER_FILTER_NAME, PRODUCTS_DATA_CUSTOMER_REQ, PRODUCTS_DATA_CUSTOMER_SUCC } from "./type";

export const saveCustomerData = (data:any ) => async (dispatch: Dispatch) => {
    try {
        
       
        dispatch({
            type: CUSTOMER_DATA_SAVE,
            payload:data
        })
    } catch (error: any) {
        console.log(error)
        
    }

}