 ;
import { getNotifications } from "api/apis/Notifications";
import {  getProducts  } from "api/apis/Product";
import { paymentMethodStatus, plans, plansHistory } from "api/apis/user";

import { Dispatch } from "redux";
import { PAYMENT_METHOD_DATA_FAIL, PAYMENT_METHOD_DATA_REQ, PAYMENT_METHOD_DATA_SUCC, PLANS_DATA_FAIL, PLANS_DATA_REQ, PLANS_DATA_SUCC, PLANS_HISTORY_DATA_FAIL, PLANS_HISTORY_DATA_REQ, PLANS_HISTORY_DATA_SUCC } from "./Type";

 


// export const PAYMENT_METHOD_DATA_REQ = "PAYMENT_METHOD_DATA_REQ"
// export const PAYMENT_METHOD_DATA_SUCC = "PAYMENT_METHOD_DATA_SUCC"
// export const PAYMENT_METHOD_DATA_FAIL = "PAYMENT_METHOD_DATA_FAIL"

 
 
export const GetPaymentStatus = () => async (dispatch: Dispatch) => {
    try {
 
        dispatch({ type: PAYMENT_METHOD_DATA_REQ })
        const res = await paymentMethodStatus( )
   console.log(res)
        dispatch({
            type: PAYMENT_METHOD_DATA_SUCC,
            payload:res.data.data
        })
    } catch (error: any) {
        console.log(error)
        dispatch({
            type: PAYMENT_METHOD_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }

}

 