import { geetSingleOrderDetails } from 'api/apis/Customer';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { ClipLoader, HashLoader } from 'react-spinners'
 





const PaymentSuccess = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(false)

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("order_id");
    console.log(id)

    const fetch = async () => {
        try {
            setLoading(true)
            const res = await geetSingleOrderDetails(id)
            console.log(res)
            setData(res?.data?.data)
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (id) {
            fetch()
        }

    }, [id])





    return (
        <>
         <Helmet>
        <title>Grab A Snak-User Payment Verify  </title>
        <meta name="description" content="Grab A Snak-User Payment Verify Page" />
      </Helmet>
       
        <div className='flex flex-col h-screen w-full items-center justify-center'>
            <div className="w-[400px]  border flex flex-col rounded-xl p-4 space-y-4">
                <div className=" h-12 flex items-center justify-between  " >
                {  data?.location?.slug && <Link to={`/${data?.location?.slug}`}>  <svg xmlns="http://www.w3.org/2000/svg"   fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                    </Link> }
               
                    <img src={data?.location?.image ? `${process.env.REACT_APP_IMAGE_URL}${data?.location?.image}` : "/images/dummyImage.png"} className="w-10 rounded-md h-10 object-cover" alt="" />
               
                </div>

                <div className='  flex flex-col items-center justify-center space-y-3  '>
                    {data?.status === "SUCCESS" && <div className="flex items-center justify-center mt-6 p-[6px] bg-green-400 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 fill-white h-6">
                            <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
                        </svg>

                    </div>}
                    {data?.status !== "SUCCESS" && loading === false ? <div className="flex items-center justify-center mt-6 p-[6px] bg-red-400 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 fill-white h-6">
                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                        </svg>

                    </div> : null}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 fill-yellow-400 h-10">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                    </svg> */}

                    {loading && <HashLoader size={30} color={"#EDB037"} />}
                    {loading && <span className='text-2xl text-yellow-500 '>
                        {"ProcessingPayment"}
                    </span>}



                    {data?.status === "SUCCESS" && <span className='text-2xl text-green-800 '>
                        {"Payment Success full!"}
                    </span>}

                    {data?.status !== "SUCCESS" && loading === false ? <span className='text-2xl text-red-800 '>
                        {"Payment failed!"}
                    </span> : null}
                </div>
                <div className='  h-12 flex items-center justify-between border-b '>
                    <span className='text-xs text-gray-400 '>
                        Payment type
                    </span>
                    <span className='text-[15px]   '>
                        {data?.payment_method}
                    </span>
                </div>
                {/* <div className='  h-12 flex items-center justify-between border-b '>
                    <span className='text-xs text-gray-400 '>
                        Bank
                    </span>
                    <span className='text-[15px]   '>
                        {data?.bank ? data?.bank : "------"}
                    </span>
                </div> */}
                <div className='  h-12 flex items-center justify-between border-b '>
                    <span className='text-xs text-gray-400 '>
                        Mobile
                    </span>
                    <span className='text-[15px]   '>
                        {data?.payee?.phone_number}
                    </span>
                </div>
                <div className='  h-12 flex items-center justify-between border-b '>
                    <span className='text-xs text-gray-400 '>
                        Email
                    </span>
                    <span className='text-[15px]   '>
                        {data?.payee?.email}
                    </span>
                </div>
                <div className='  h-12 flex items-center justify-between '>
                    <span className='text-base font-bold   '>
                        Amount Paid
                    </span>
                    <span className=' text-base  font-bold   '>
                        $ {data?.amount}
                    </span>
                </div>
            </div>
            <span className={"mt-16"}>
            © Copyright Grab A Snak {format(new Date (),"yyyy")}
            </span>
            </div>

            </>
    )
}

export default PaymentSuccess