import { createOrder } from 'api/apis/Customer'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { Info } from 'phosphor-react'

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { emptyCart } from 'redux/actions/customer/cartAction'
import { saveCustomerData } from 'redux/actions/customer/products/customerDetails'
import { Input } from 'styles/Input'
import { RootState } from 'types'


const DetailsPopUp = ({ price }) => {
    const [showPopUp, setShowPopUp] = useState(false)
    const data = useSelector((state: RootState) => state.cart)

    let products = useSelector((state: any) => {
        return state.productsForCustomer.products;
    });
    useEffect(() => {
        setShowPopUp(true)
    }, [])

    let customer = useSelector((state: any) => {
        return state.customerData.data;
    });

    const clickHandler = (e) => {
        // e.stopPropagation();
    }
    console.log(data)
    const navigate = useNavigate()

    const [showInfo, setShowInfo] = useState(false)

    const dispatch = useDispatch()
    const formik = useFormik<any>({
        initialValues: {
            customer_name: customer?.customer_name ? customer?.customer_name : "",
            customer_email: customer?.customer_email ? customer?.customer_email : "",
            customer_phone_number: customer?.customer_phone_number ? customer?.customer_phone_number : "",
            locationId: products.location._id,
            products: []

        },
        // validate,
        onSubmit: async (values) => {

            toast.loading("Creating Order")
            try {

                dispatch(saveCustomerData(values))
                values.products = data.map((item) => {
                    return {
                        product: item.item.product._id,
                        quantity: item.qty === 0 ? 1 : item.qty
                    }
                })
                const res = await createOrder(values)
                
                if (res.data.data.checkout_session.url ) {
                    window.location.replace(res.data.data.checkout_session.url)
                }

             

            } catch (error: any) {
                console.log(error)
                toast.dismiss()
                console.log(error?.response)
                toast.error(error?.response?.data?.message)

            }

        }


    })









    return (
        <>
            <div onClick={clickHandler} className='sticky bottom-0 left-0 z-[99] w-full h-[100vh] bg-black bg-opacity-40 '>
                <div onClick={(e) => e.stopPropagation()} className={classNames("right-0 w-full p-8 bg-[#F8F8F8]  absolute bottom-0 border  h-2/3 rounded-t-[20px] z-40 ease-in-out duration-500", showPopUp ? "translate-y-0" : "translate-y-full",)}>
              <div className="w-full flex  items-center py-4 justify-center">
              <label className=' text-theme-black pl-1' htmlFor="product title">Enter your details</label>
              </div>
               
                    <div className='overflow-y-auto h-[94%] hide-scrollBar'>
                        <div className='py-1.5 text-sm'>
                            <label className=' text-theme-black pl-1' htmlFor="product title">Full Name</label>
                            <Input name={"customer_name"} value={formik.values.customer_name} onChange={formik.handleChange} required type="text" />
                        </div>

                        <div className='py-1.5 text-sm'>
                            <label className=' text-theme-black pl-1' htmlFor="product title">Your Email</label>
                            <Input name={"customer_email"} value={formik.values.customer_email} onChange={formik.handleChange} required type="text" />
                        </div>

                        <div className='py-1.5 text-sm'>
                            <label className=' text-theme-black pl-1' htmlFor="product title">Phone Number</label>
                            <Input name={"customer_phone_number"} value={formik.values.customer_phone_number} onChange={formik.handleChange} required type="text" />
                        </div>

                        {/* <div className='py-1.5 text-sm'>
                            <label className=' text-theme-black pl-1' htmlFor="product title">ZIP / Post Code</label>
                            <Input name={"customer_name"} value={formik.values.customer_name} onChange={formik.handleChange} required type="text" />
                        </div> */}

                        <div className='flex flex-col gap-2 pt-3 items-center'>
                            <Info onClick={() => setShowInfo(!showInfo)} size={24} className='text-theme-yellow' weight='fill' />
                            {showInfo && <p className='text-sm text-center'>We use this information to track your order and transaction history. </p>}
                        </div>
                    </div>

                    <button onClick={() => formik.handleSubmit()} className='py-2 w-full bg-theme-yellow text-black rounded-md font-medium'>Pay $ {price.toFixed(2)}</button>
                </div>
            </div>
        </>
    )
}

export default DetailsPopUp