import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";



function PrivateRoute({ children }) {
    let user = useSelector((state: any) => {
        return state.user.data;
    });



    // useEffect(() => {
    //     if (user?.status === false)
    //         toast.error("Email Not Verifyed")
    // }, [user])
    return user?.active_plan&&user?.complete&&user?.status&&user?.role==="SUBSCRIBER" ? children:user&&user?.role!=="SUBSCRIBER"?<Navigate to="/login" />: user&&!user?.active_plan? <Navigate to="/profilesetup" />: <Navigate to="/login" />;
}



export default PrivateRoute
