
import { ArrowLeft, CaretRight, MapPin } from 'phosphor-react'
import React, { useEffect, useState } from 'react'

const SingleOrderScreen = ({ order, orderDetailsHandler, data }) => {
    const [foinalOrder, setFinalOrder] = useState(order)
    const [price, setPrice] = useState(0)




    useEffect(() => {
        if (foinalOrder) {
            const index = data?.products?.findIndex((item) => item._id === foinalOrder._id);

            setPrice(data?.prices[index])
        }

    }, [foinalOrder])

    const orderClickHandler = (o) => {
        setFinalOrder(o)
    }
    console.log(data)
    return (
        <>
            <div className='p-4'>
                <div className='flex'>
                    <div onClick={orderDetailsHandler} className='flex cursor-pointer items-center gap-2'>
                        <ArrowLeft size={23} weight='bold' />
                        <p>Order Details</p>
                    </div>
                </div>

                <div className='pt-6'>
                    <div className="flex items-center space-x-3 ">


                        <p className='py-2 text-[#A5A5A5] border-b'>Order ID - {data?.order_id}</p>
                        {data?.status === "SUCCESS" && <p className='bg-[#20904A] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Successful"}</p>}
                        {data?.status !== "SUCCESS" && <p className='bg-[#DD0500] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Failed"}</p>}
                    </div>
                    <div className='p-2 gap-3 rounded-md my-2 flex'>
                        <div className='p-3  bg-white rounded-md w-1/3 border flex justify-center'>
                            <img className=' object-cover  h-[6rem] ' src={foinalOrder?.image ? `${process.env.REACT_APP_IMAGE_URL}${foinalOrder?.products[0]?.image}` : "/noPrdouctImage.jpeg"} alt="img" />
                        </div>
                        <div className='flex-1'>
                            <div className='grid gap-2'>
                                <div className='grid gap-1'>
                                    <p className='text-lg text-[#20904A]  font-bold  '>$ {price ? price?.toFixed(2) : "---"}</p>
                                    <p className='  font-medium text-[#414141]'>{foinalOrder?.name}</p>
                                </div>
                                <div className='grid gap-1'>
                                    <p className='flex py-1 items-center text-sm text-[#B5B6B7]'><MapPin className='opacity-50 mr-1' weight='fill' size={14} />{data?.location?.address.city},{" "}{data?.location?.address.state},{" "}{data?.location?.address.country},{" "}{data?.location?.address.zipcode}</p>
                                    <p className='text-sm'>{data?.location?.slug}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* other items with the same order id */}
                    {data?.products?.length !== 0 && <>
                        <p className='py-2 text-[#A5A5A5] border-b'>Other items in this order</p>
                        <div className='pt-3'>
                            {
                                data?.products?.filter(item => item._id !== foinalOrder._id).map((i, index) => {
                                    return <div onClick={() => orderClickHandler(i)} key={index} className='p-2 gap-3 rounded-md my-2 flex bg-[#F8F8F8] '>
                                        <div className='p-3  bg-white rounded-md w-1/4 flex justify-center'>
                                            <img className=' object-cover  h-[4rem] ' src={i?.image ? `${process.env.REACT_APP_IMAGE_URL}${i?.image}` : "/noPrdouctImage.jpeg"} alt="img" />
                                        </div>
                                        <div className='flex flex-1 justify-between items-center'>
                                            <div className='grid gap-2'>
                                                <p className='  font-medium'>{i?.name}</p>
                                                <p className='text-sm text-[#AAAAAA]  font-medium  '>{i?.time}</p>
                                            </div>
                                            <div className='flex gap-4 p-2  items-center rounded-md  '>
                                                <CaretRight size={20} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default SingleOrderScreen