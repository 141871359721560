import { getPasswordResetLink } from 'api/apis/user';
import { ArrowCircleLeft, CaretLeft, User } from 'phosphor-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';



const ForgotPs = ({ }) => {

  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState("")

  const chnagePassowrd = async () => {

    try {
      toast.loading("Sending Email")
      let data = {
        email: email
      }
      const res = await getPasswordResetLink(data)
      toast.dismiss()
      setEmailSent(true)
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">

              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">
                   Email has been sent successfully
                </p>
                
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ))
    } catch (error: any) {
      toast.dismiss()
      console.log(error?.response)
      toast.error(error?.response?.data?.message)
    }
  }

  if (emailSent) {
    return (
      <>
        <Helmet>
          <title>Grab A Snak-Forget Password </title>
          <meta name="description" content="Grab A Snak-Forget Password Login" />
        </Helmet>
        <div className='flex bg-[#F7F7F7]  h-[100vh] justify-center items-center'>
          <Link to="/login"><CaretLeft className='absolute top-10 left-10 p-2 rounded-full border border-[#CDD1CC] bg-[#CDD1CC text-[#CDD1CC] cursor-pointer' size={36} weight='bold' /></Link>
          <div className='lg:w-[35%] w-[90%] rounded-xl shadow-md p-12 bg-white'>
            <img src="images/GAS.png" alt="" />
            <p className='lG:my-10 my-6 flex gap-2 '><User className='text-[#FA4756]' size={26} weight="bold" /> <span  className='text-theme-black lg:text-[30px] text-[20px] font-bold'>You've got mail!</span></p>
            <p className='lg:text-base text-sm'>We've just sent an email to reset your password.</p>

            <form className='mt-6 ' action="">
              <p className='  mt-8'> Didn't get it?  <span onClick={()=>chnagePassowrd()}   className='text-theme-yellow cursor-pointer'>Resend email </span>  </p>
            </form>
          </div>
          <img src="images/Group632.svg" className='absolute bottom-0' alt="" />
        </div>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Grab A Snak-Forget Password </title>
          <meta name="description" content="Grab A Snak-Forget Password Login" />
        </Helmet>
        <div className='flex bg-[#F7F7F7]  h-[100vh] justify-center items-center'>
          <Link to="/login"><CaretLeft className='absolute top-10 left-10 p-2 rounded-full border border-[#CDD1CC] bg-[#CDD1CC text-[#CDD1CC] cursor-pointer' size={36} weight='bold' /></Link>
          <div className='lg:w-[35%] w-[90%] rounded-xl shadow-md p-12 bg-white'>
            <img src="images/GAS.png" alt="" />
            <p className='lG:my-10 my-6 flex gap-2 '><User className='text-[#FA4756]' size={26} weight="bold" /> <span className='text-theme-black lg:text-[30px] text-[20px] font-bold'>Forgot  Your Password? </span></p>
            <p className='lg:text-base text-sm'>No worries, just enter your Grab a Snak email to reset your password. </p>

            <form className='mt-6' action="">
              <label className=' text-theme-black pl-1' htmlFor="email">Your Email:</label>
              <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='jhonsmith@example.com' className='w-full focus:outline-[#103734] my-2 mb-3 border rounded-full px-4 py-2' type="email" />

              <button type="button" onClick={chnagePassowrd} className='w-full py-2 rounded-full bg-theme-green text-white mt-4 mb-6'>Reset Password</button>
            </form>
          </div>
          <img src="images/Group632.svg" className='absolute bottom-0' alt="" />
        </div>
      </>
    )
  }

}

export default ForgotPs