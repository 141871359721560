
import SingleOrderScreen from 'app/coustomerComps/SingleOrderScreen'
import TopNav from 'app/coustomerComps/TopNav'
import classNames from 'classnames'
import { format } from 'date-fns'
import { reverse } from 'dns'


import { CaretRight, MagnifyingGlass } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { getProductsByLocationIdClient } from 'redux/actions/customer/products/product'
import { getTransactionsByPhoneClient } from 'redux/actions/customer/products/Trasnactions'

const Profile = () => {
    const [active, setActive] = useState({ transaction: true, orders: false })
    const [showSingleOrder, setShowSingleOrder] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState<any>()
    const [selectedData, setSelectedData] = useState<any>()

    let customer = useSelector((state: any) => {
        return state.customerData;
    });

    let transactions = useSelector((state: any) => {
        return state.transactionsData;
    });
    const orderClickHandler = (O) => {
        setShowSingleOrder(true)
        setSelectedData(O)
        setSelectedOrder(O?.products?.[0])
        //  setSelectedOrder(FakeOrders[0])
    }

    const dispatch = useDispatch()
    let { slug } = useParams();
    useEffect(() => {
        if (customer?.data?.customer_phone_number) {
            dispatch(getTransactionsByPhoneClient(customer?.data?.customer_phone_number))
        }

    }, [customer?.data?.customer_phone_number])

    useEffect(() => {
        if (slug) {
            dispatch(getProductsByLocationIdClient(slug))
        }

    }, [slug])
    return (
        <>
            <Helmet>
                <title>Grab A Snak-Profile</title>
                <meta name="description" content="Grab A Snak-Profile Page" />
            </Helmet>
            <div className='flex justify-center'>
                <div className='max-w-[762px] w-full'>
                    <TopNav />
                    {showSingleOrder ? <SingleOrderScreen orderDetailsHandler={() => setShowSingleOrder(false)} order={selectedOrder} data={selectedData} /> : <>
                        <div className='grid grid-cols-2 '>
                            <p onClick={() => setActive({ transaction: true, orders: false })} className={classNames('text-center text-sm py-3 font-medium', active.transaction && "border-b-[3px] border-theme-yellow")}>Transaction History</p>
                            <p onClick={() => setActive({ transaction: false, orders: true })} className={classNames('text-center text-sm py-3 font-medium', active.orders && "border-b-[3px] border-theme-yellow")}>My Orders</p>
                        </div>

                        {active.transaction && (transactions?.data?.length == 0 ?
                            <div className='flex flex-col px-6 w-full items-center pt-6'>
                                <img src="/images/noTransaction.svg" alt="" />
                                <div className='w-full grid gap-3 pt-7'>
                                    {/* <input type="text" className='w-full py-4 px-4 bg-[#F8F8F8] rounded-md focus:outline-none' placeholder='Email ID' /> */}
                                    {!transactions.loading ? <Link to={`/${slug}`}><button className='py-3 bg-theme-yellow w-full text-black rounded-md font-medium'>Next</button></Link> :
                                        <div className='py-3 bg-theme-yellow w-full flex items-center justify-center space-x-4 text-black rounded-md font-medium'>

                                            <span>Loading</span>
                                            <ClipLoader size={20} />
                                        </div>}
                                </div>
                            </div>
                            :
                            <div className='p-6 grid gap-3'>
                                {transactions?.data?.map((t, index) => {
                                    return <div key={index} className='bg-[#F8F8F8]  rounded-[10px] p-4'>
                                        <div className='border-b pb-4'>
                                            <div className='flex items-center justify-between'>
                                                <p className='text-theme-black text-xs text-opacity-70'>{format(new Date(t.createdAt), "dd MMM, yyyy  hh:mm a ")}</p>
                                                {t.status === "SUCCESS" && <p className='bg-[#20904A] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Successful"}</p>}
                                                {t.status !== "SUCCESS" && <p className='bg-[#DD0500] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Failed"}</p>}
                                            </div>
                                            <div className='pt-2 flex items-center justify-between'>
                                                <div className='flex flex-col space-y-1'>
                                                    
                                                <p className='text-[#BABEBF] text-sm'>Order ID</p>
                                                <p className='text-sm'>{t?.order_id}</p>
                                                </div>
                                                <div className='flex flex-col space-y-1'>
                                                    
                                                    <p className='text-[#BABEBF] text-sm'>{" "} </p>
                                                    <p className='text-sm'>{t?.location?.name}</p>
                                                    </div>
                                            </div>
                                        </div>

                                        <div className='pt-2 flex items-center justify-between'>
                                            <div>
                                                <p className='text-[#BABEBF] text-sm'>Payment Method</p>
                                                <p className='text-sm'>{t?.paymentMethod ? t?.paymentMethod : "---------------------"}</p>
                                            </div>
                                            <p className={classNames('font-bold text-sm', t.status === "SUCCESS" && 'text-[#4CAF3F]')}>$ {t?.amount?.toFixed(2)}</p>
                                        </div>

                                    </div>
                                })}
                            </div>
                        )}


                        {/* prev orders */}
                        <div className='p-6 grid gap-3'>


                            {/* order history */}
                            {active.orders && (transactions?.data?.length == 0 ?
                                <div className='flex flex-col px-6 w-full items-center pt-6'>
                                    <img src="/images/noTransaction.svg" alt="" />
                                    <div className='w-full grid gap-3 pt-7'>
                                        {/* <input type="text" className='w-full py-4 px-4 bg-[#F8F8F8] rounded-md focus:outline-none' placeholder='Email ID' /> */}
                                        {!transactions.loading ? <Link to={`/${slug}`}><button className='py-3 bg-theme-yellow w-full text-black rounded-md font-medium'>Next</button></Link> :
                                            <div className='py-3 bg-theme-yellow w-full flex items-center justify-center space-x-4 text-black rounded-md font-medium'>
                                                <span>Loading</span>
                                                <ClipLoader size={20} />
                                            </div>}
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='relative w-full'>
                                        {/* <input type="text" placeholder='Search Product' className='w-full h-full pr-3 pl-8 py-2 rounded-[10px] border' /> */}
                                        {/* <MagnifyingGlass className='absolute top-1/2 -translate-y-1/2 left-2' size={18} /> */}
                                    </div>

                                    {
                                        transactions?.data?.map((i, index) => {
                                            return <div onClick={() => orderClickHandler(i)} key={index} className='p-2 gap-3 rounded-md my-2 flex bg-[#F8F8F8] '>
                                                <div className='p-3  bg-white rounded-md w-1/4 flex justify-center'>
                                                    <img className=' object-cover  h-[4rem] ' src={i?.products[0]?.image ? `${process.env.REACT_APP_IMAGE_URL}${i?.products[0]?.image}` : "/noPrdouctImage.jpeg"} alt="img" />
                                                </div>
                                                <div className='flex flex-1 justify-between items-center'>
                                                    <div className='grid gap-2'>
                                                        <p className='  font-medium'>{i?.products[0]?.name}</p>
                                                        <p className='text-sm text-[#AAAAAA]  font-medium  '>{format(new Date(i.createdAt), "dd MMM, yyyy  hh:mm a ")}</p>
                                                    </div>
                                                    <div className={"flex items-center space-x-3"}>
                                                    {i?.status === "SUCCESS" && <p className='bg-[#20904A] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Successful"}</p>}
                                                    {i?.status !== "SUCCESS" && <p className='bg-[#DD0500] px-3 py-1.5 text-white text-xs font-bold rounded-lg'>{"Failed"}</p>}

                                                    <div className='flex gap-4 p-2  items-center rounded-md  '>
                                                        <CaretRight size={20} />
                                                    </div>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                        })
                                    }

                                </>
                            )}
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default Profile