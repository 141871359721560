import classNames from 'classnames'
import { Tote, ToteSimple, User } from 'phosphor-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import AllProducts from './AllProducts'

const TopNav = () => {
    let products = useSelector((state: any) => {
        return state.productsForCustomer;
      }); 
      let customer = useSelector((state: any) => {
        return state.customerData;
      }); 
      let {slug}= useParams()
  return (
    <>
        <div className='px-4 py-2  border-b flex justify-between sticky top-0 w-full bg-white z-[99] items-center'>
            <Link to={`/${slug}`}>
            <div  className={classNames(' flex gap-2 py-2  items-center' )}>
                <img src={products?.products?.location?.image ? `${process.env.REACT_APP_IMAGE_URL}${products?.products?.location?.image}` : "/images/dummyImage.png"} className="w-10 rounded-md h-10 object-cover" alt="" />
                <div>
                    <h2 className='font-smibold text-sm'>{products?.products?.location?.name}</h2>
                </div>
            </div>
            </Link>
            <div className='flex gap-10 items-center'>
                <div>
                    <Link to={`/${slug}/cart`}><Tote className='text-theme-green' size={26} weight="fill" /></Link>
                </div>
                <div>
                {customer?.data?.customer_phone_number&&(<Link to={`/${slug}/profile`}><User className='text-theme-green' size={26} weight='fill'/></Link>)}
                </div>
            </div>
        </div>

    </>
  )
}

export default TopNav