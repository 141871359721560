import { Dispatch } from "redux";
import { USER_LOGIN_FLOW } from "./Type";



export const getLoginFlow = (data: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: USER_LOGIN_FLOW,
        payload: data
    })
}