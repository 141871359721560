import { categoriesForCustomer, productsForCustomer, transactionOfCustomer } from "api/apis/Customer";
import { Dispatch } from "redux";
import { TRASACTION_DATA_CUSTOMER_FAIL, TRASACTION_DATA_CUSTOMER_REQ, TRASACTION_DATA_CUSTOMER_SUCC } from "./type";



 
export const getTransactionsByPhoneClient = (phone: string | number ) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TRASACTION_DATA_CUSTOMER_REQ })
        const res = await transactionOfCustomer(phone)
       
        dispatch({
            type: TRASACTION_DATA_CUSTOMER_SUCC,
            payload: res.data.data.reverse()
        })
    } catch (error: any) {
        console.log(error)
        dispatch({
            type: TRASACTION_DATA_CUSTOMER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }

}