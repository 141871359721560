
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { reactLocalStorage } from 'reactjs-localstorage'
import { GetPaymentStatus } from 'redux/actions/user/PaymentStatus'
import { getLoginFlow } from 'redux/actions/user/SignUpAction'
import { getSingleUser } from 'redux/actions/user/UserAction'
import styled from 'styled-components'
import Loading from '../loading/Loading'



const ImageDiv = styled.div`
    background-image: url("images/Header.png")
`

const VerifyComponent = () => {

    let flow = useSelector((state: any) => {
        return state.flow.data;
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const checklogin = async () => {
        try {
            if (flow?.authType === "google") {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/google/callback${window.location.search}`)
                console.log(res)
                reactLocalStorage.set("access_token", res.data.data.token)
                dispatch(getSingleUser((data) => {

                    if (data.active_plan&&data.complete&&data.status) {
                        dispatch(GetPaymentStatus())
                        navigate('/')
                       
                    } else {
                        navigate('/profilesetup')
                    }
                    dispatch(getLoginFlow({}))
                }))
            } else if (flow.authType === "facebook") {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/fb/callback${window.location.search}`)
                console.log(res)
                reactLocalStorage.set("access_token", res.data.data.token)
                dispatch(getSingleUser((data) => {

                    if (data.active_plan&&data.complete&&data.status) {
                        dispatch(GetPaymentStatus())
                        navigate('/')
                    } else {
                        navigate('/profilesetup')
                    }
                    dispatch(getLoginFlow({}))
                }))
            }


        } catch (error) {

            if (flow?.authBy === "login") {
                navigate('/login')
                toast.error("Login Failed Plz Try Again ")
                dispatch(getLoginFlow({}))
            } else {
                navigate('/signup')
                toast.error("Signup Failed Plz Try Again ")
                dispatch(getLoginFlow({}))
            }
        }
    }
    useEffect(() => {
        if (window.location.search) {

            checklogin()
        }
    }, [window.location.search])

    return (
        <>
            <div className='h-[100vh] relative w-[100vw] '>
                <ImageDiv className='py-8 pb-20'>
                    <div className='pt-6 pb-10 flex flex-col items-center'>
                        <img className='py-6' src="images/GAS.png" alt="" />
                        <div className='text-white text-center'>
                            <h2 className='text-[30px] font-bold'>Welcome to Grab-a-Snak 👍</h2>
                            <p>Follow these steps to get started</p>
                        </div>
                    </div>
                </ImageDiv>
                <div className='flex -mt-24 justify-center'>
                    <div className='w-2/3 z-10 flex items-center justify-center relative p-6 min-h-[65vh] bg-white shadow rounded-[20px]'>
                        <Loading text={"verifying user ..."} />
                    </div>
                </div>
                <img src="images/Group632.svg" className='absolute bottom-0' alt="" />
            </div>
        </>
    )
}

export default VerifyComponent