import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";



function LoginRedirect({ children }) {
    let user = useSelector((state: any) => {
        return state.user.data;
    });

    return !user?.status ||user?.role!=="SUBSCRIBER" ? children : <Navigate to="/" />;
  }
 

 
export default LoginRedirect
