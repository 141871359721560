// import classNames from 'classnames'
// import { CaretLeft } from 'phosphor-react'
// import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
// import StripeCheckout from 'react-stripe-checkout';
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import {PaymentElement} from '@stripe/react-stripe-js';
// import CheckOutForm from './CheckOutFrom'

// const PaymetScreen = () => {
//     const [activeSubs, setActiveSubs] = useState({monthly:true, yearly:false})



//     // stripe integration

//     const clientSecret = "pi_3LaKklBXPxSQkpok1ex1B0Wr_secret_7Wc0lPgULqUpfXy8zTXA73snJ";

  
//   const appearance = {
//     theme: 'stripe',
//   };
//   const options:any = {
//     clientSecret,
//     appearance,
//   };

//     const stripePromise = loadStripe('pk_test_51KhYlOBXPxSQkpokzjhYqRjsPRgIARzpck1g7vnbYWkbaMH5NGGaTBoURk9q5UIyEUp3YU4NIf23LSF14dqetqsr00uTW4Mvhm');
// const  onToken = (token) => {
//     fetch('/save-stripe-token', {
//       method: 'POST',
//       body: JSON.stringify(token),
//     }).then(response => {
//       response.json().then(data => {
//         alert(`We are in business, ${data.email}`);
//       });
//     });
//   }

//   return (
//     <>
//         {/* <div className='flex w-full'>
//             <div className='flex-1  pt-10 pl-10'>
//                 <div className='flex gap-3'>
//                     <Link to="/login"><CaretLeft  className=' p-2 rounded-full border border-[#CDD1CC] bg-[#CDD1CC text-[#CDD1CC] cursor-pointer' size={36} weight='bold'  /></Link>
//                     <img src="images/GAS.png" alt="" />
//                 </div>

//                 <div className='pt-32 pl-8'>
//                     <p className='text-[#707070] text-lg'>Choose a pricing option</p>
//                     <p className='text-sm mt-8 opacity-50'>Trial Period</p>
//                     <p className='text-sm mt-3'>Feb 6th - Feb 13th, 2022</p>
//                     <p className='font-bold text-theme-yellow mt-3'>7-day, Free Trial</p>

//                     <div className={classNames('mt-8 flex w-4/5 gap-4')}>
//                         <div onClick={() => setActiveSubs({monthly:true, yearly:false})} className={classNames('flex-1 p-6 rounded-2xl border',activeSubs.monthly?'border-theme-yellow ' :'bg-[#CDD1CC] bg-opacity-20 border-[#CDD1CC] border-opacity-20')}>
//                             <p className='text-xs'>Subscription</p>
//                             <p className='font-bold text-[22px] text-theme-yellow mt-2'>($30.00/mo)</p>
//                             <p className='text-xs mt-2'>$30.00/mo</p>
//                         </div>
//                         <div onClick={() => setActiveSubs({monthly:false, yearly:true})} className={classNames('flex-1 p-6 rounded-2xl border',activeSubs.yearly?'border-theme-yellow ' :'bg-[#CDD1CC] bg-opacity-20 border-[#CDD1CC] border-opacity-20' )}>
//                             <p className='text-xs'>Subscription</p>
//                             <p className={classNames('font-bold text-[22px] mt-2', activeSubs.yearly&& " text-theme-yellow")}>($360.00/yr)</p>
//                             <p className='text-xs mt-2'>$360.00/yr</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className='flex-1'>
//                 <Elements stripe={stripePromise}>
//                 {clientSecret && (
//                     <Elements options={options} stripe={stripePromise}>
//                     <CheckOutForm />
//                     </Elements>
//                     )}
//                 </Elements>
//             </div>

//         </div> */}
// <StripeCheckout
//         token={onToken}
//         stripeKey={"pk_test_51KhYlOBXPxSQkpokzjhYqRjsPRgIARzpck1g7vnbYWkbaMH5NGGaTBoURk9q5UIyEUp3YU4NIf23LSF14dqetqsr00uTW4Mvhm"}
//       />

//     </>
//   )
// }

// export default PaymetScreen

import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
 
 
import React from "react";
import { Helmet } from "react-helmet-async";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_test_51KhYlOBXPxSQkpokzjhYqRjsPRgIARzpck1g7vnbYWkbaMH5NGGaTBoURk9q5UIyEUp3YU4NIf23LSF14dqetqsr00uTW4Mvhm');
  }

  return stripePromise;
};

const Checkout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const item = {
    price: '100000',
    quantity: 1
  };

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    // payee, payment_method, products, createdAt, status, order_id, quantities, amount, location
    const {paymentIntent, error} = await stripe.confirmCardPayment(
        'pi_3LcAjrBXPxSQkpok13Se8arF_secret_uk1Ugr4HwRY1wy5XyKFZC7Ud5',
       
        
      );
    // const { error } = await stripe.redirectToCheckout();
    // console.log("Stripe checkout error", error);

    // if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  return (<>
  <Helmet>
        <title>Grab A Snak-Profile Setup</title>
        <meta name="description" content="Grab A Snak-Profile Setup Page" />
      </Helmet>
  
    <div className="checkout">
      <h1>Stripe Checkout</h1>
      <p className="checkout-title">Design+Code React Hooks Course</p>
      <p className="checkout-description">
        Learn how to build a website with React Hooks
      </p>
      <h1 className="checkout-price">$19</h1>
  
      <button
        className="checkout-button"
        onClick={redirectToCheckout}
        disabled={isLoading}
      >
        <div className="grey-circle">
          <div className="purple-circle">
            
          </div>
        </div>
        <div className="text-container">
          <p className="text">{isLoading ? "Loading..." : "Buy"}</p>
        </div>
      </button>
    </div>
    </>
  );
};

export default Checkout;
