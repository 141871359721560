import DetailsPopUp from 'app/coustomerComps/DetailsPopUp'
import TopNav from 'app/coustomerComps/TopNav'
import { Minus, Plus } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { AddtoCart } from 'redux/actions/customer/cartAction'
import { RootState } from 'types'

const CartCustomer = () => {
    const data = useSelector((state: RootState) => state.cart)
    console.log('data', data);
    const dispatch = useDispatch()
    let total = 0;


    for (var i = 0; i < data.length; i++) {
        total = total + data[i].item.price * data[i].qty!
    }

    let products = useSelector((state: any) => {
        return state.productsForCustomer;
    });
    // details popup

    const [showDetailsPopUp, setShowDetailsPopUp] = useState(false)


    const CheckOut = () => {
        setShowDetailsPopUp(true)
    }

    console.log(data)
    useEffect(() => {
        if (showDetailsPopUp) {
          document.body.onclick = function () { setShowDetailsPopUp(false) }
        }
      }, [])
    
 
 

    return (
        <>
            <Helmet>
                <title>Grab A Snak-Cart</title>
                <meta name="description" content="Grab A Snak-Cart Page" />
            </Helmet>
            <div className='flex justify-center'>
                <div className='max-w-[762px] w-full'>
                    <TopNav />
                    <div className='relative h-[calc(100vh-81px)]'>
                        <div className='px-4 h-[calc(100%-88px)] overflow-y-auto'>
                            {
                                data.map((i, index) => {

                             
                                    return <div key={index} className='p-2 gap-3 rounded-md my-2 flex bg-[#F8F8F8] '>
                                        <div className='p-3  bg-white rounded-md w-1/4 flex justify-center'>
                                            <img className=' object-cover  h-[4rem] ' src={i?.item?.product?.image ? `${process.env.REACT_APP_IMAGE_URL}${i?.item?.product?.image}` : "/noPrdouctImage.jpeg"} alt="img" />
                                        </div>
                                        <div className='flex flex-1 pb-3 justify-between items-end'>
                                            <div className=''>
                                                <p className='text-sm text-[#AAAAAA] pt-3 font-medium'>{i?.item?.product?.name}</p>
                                                <p className='text-lg font-bold pt-3 text-black'>$ {i.item.price.toFixed(2)}</p>
                                            </div>
                                            <div className='flex gap-4 p-2  items-center rounded-md bg-white '>
                                                <Minus  onClick={() => {
                                                    if (i?.qty != 1){
                                                        dispatch(AddtoCart(i.item._id, products?.data,"substract"))
                                                    }
                                                  
                                                    
                                                    }} className='cursor-pointer' size={16} />
                                                <p>{i.qty}</p>
                                                <Plus onClick={() => {

                                                    if (  i?.qty < i?.item?.quantity){
                                                        dispatch(AddtoCart(i.item._id, products?.data))
                                                    }
                                                }} className='cursor-pointer' size={16} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='absolute bottom-0  bg-gradient-to-t from-[#fff] to-transparent left-0 w-full px-4'>
                            <div className='flex justify-between items-center'>
                                <p className='text-sm font-bold'>Total</p>
                                <p className='font-bold'>$ {total.toFixed(2)}</p>
                            </div>
                            <div className='my-2'>
                                <button onClick={() => {
                                  
                                    setShowDetailsPopUp(true)
                                }} className='py-3 bg-theme-yellow w-full text-black rounded-md font-medium'>
                                    {/* Check Out */}
                                    Grab It
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showDetailsPopUp && <DetailsPopUp price={total} />}
        </>
    )
}

export default CartCustomer